import { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { fetchProgImage } from '../../api'

const Programmes = () => {
    const { t } = useTranslation();
    const [progImage, setProgImage] = useState([]);

    useEffect(() => {
        fetchProgImage().then((resp) => {
            setProgImage(resp.featured_image_src);
        })
    }, []);


    return (
        <div className="col-12 home-section home-section-yellow">
            <div className="home-section-inner row">
                <div className="col-12 col-sm-7 d-block d-sm-none">
                    <h1 className="home-section-title">{t('home.programmes')}</h1>

                    {/* <div className='home-image'>
                        <img className='home-section-image' src={progImage} alt='' />
                    </div> */}
                </div>

                <div className="col-12 col-lg-5">
                    <h2 className="home-section-subtitle">{t('home.programmes_title')}</h2>
                    <p>{t('home.programmes_desc')}</p>
                    <NavLink
                        to="/programmes"
                        className="home-section-link link-underline"
                    >
                        {t('home.programmes_link')} <i class="fas fa-angle-right"></i>
                    </NavLink>
                </div>

                <div className="col-12 col-sm-7 d-none d-sm-block">
                    <h1 className="home-section-title">{t('home.programmes')}</h1>

                    {/* <div className='home-image-right d-none d-sm-block'>
                        <img className='home-section-image' src={progImage} alt='' />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Programmes;