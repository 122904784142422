import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import i18n from '../../../i18n';
import i18next, { t } from 'i18next';
import { fetchPost, fetchAuthor, fetchImageById } from '../../../api';
import PageHeader from '../../Header/PageHeader';
import Loader from '../../Loader'
import moment from 'moment'
import SharePost from './SharePost';
import RelatedPosts from './RelatedPosts';
import PostAuthor from './PostAuthor';
// import { useLocation } from "react-router-dom";

import FEAT_IMAGE from '../../../assets/images/feat_image_2.jpg'
import LOGO from '../../../assets/images/logo.svg'

import { Helmet } from 'react-helmet-async';

const Post = (props) => {
    const [data, setData] = useState();
    const [imageLink, setImageLink] = useState();
    const [isLoading, setLoading] = useState(true);

    const id = window.location.pathname.split('/')[2];

    useEffect(() => {
        fetchPost(id).then((resp) => {
            setData(resp[0]);
            setLoading(false);
        })
    }, [id]);

    useEffect(() => {
        fetchImageById(data?.featured_media).then((resp) => {
            setImageLink(resp.source_url);
        }).catch(() => {
            setImageLink(FEAT_IMAGE);
        })
    }, [data]);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const currentLanguage = (i18n.language === 'en' ? 'en' : 'sq');
    const [lang, setLang] = useState(currentLanguage);
    const [translation, setTranslation] = useState(null)

    i18next.on('languageChanged', function (lng) {
        if (lng === 'sq') {
            setLang('sq')

            setTranslation(data?.translation.sq)
        }
        else if (lng === 'en') {
            setLang('en')
            setTranslation(data?.translation.en)
        }
    });

    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (!translation) {
            navigate("/");
            return;
        }

        fetchPost(translation).then((resp) => {
            setData(resp);
        })
    }, [lang, translation]);

    // let image_url = data?.featured_image_src;

    // if (data?.featured_image_src.indexOf('default.png') > -1) {
    //     image_url = FEAT_IMAGE
    // }
    


    console.log(data)

    return (
        (data && !isLoading ?
            <>
                <Helmet>
                    <title>Galeria Qahili - {data?.title.rendered}</title>
                    <meta name="description" content={data?.content.rendered} />
                    <meta property="og:title" content={data?.title.rendered} />
                    <meta property="og:description" content={data?.content.rendered} />
                    <meta property="og:image" content={imageLink} />
                    <meta property="og:type" content="article" />
                    {/* Add more meta tags as needed */}
                </Helmet>
                <div className='container post-section'>

                    <PageHeader header={t('gq')} />
                    <h1 dangerouslySetInnerHTML={{ __html: data?.title.rendered }} />
                    <h6 className='post-date'>{
                        data?.date && moment(data?.date).format("DD.MM.YYYY")
                    }</h6>

                    <img className='post-featured-image' src={data?.featured_image_src.indexOf('default.png') > -1 ? FEAT_IMAGE : data?.featured_image_src} alt='' />

                    <div dangerouslySetInnerHTML={{ __html: data?.content.rendered }} />

                    <PostAuthor author_name={data?.acf.author_name} author_desc={data?.acf.author_job_title} author_image={LOGO} />

                    <SharePost />

                    <RelatedPosts category_id={data?.categories} />
                </div>
            </> : <Loader />
        )
    )
}

export default Post;