import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { fetchDBImage } from '../../api'

const Databaza = () => {
    const { t } = useTranslation();
    const [dbImage, setDbImage] = useState([]);

    useEffect(() => {
        fetchDBImage().then((resp) => {
            setDbImage(resp.featured_image_src);
        })
    }, []);

    return (
        <div className="col-12 home-section home-section-pink">
            <div className="home-section-inner row">
                <div className="col-12 col-lg-7">
                    <h1 className="home-section-title">{t('home.databaza')}</h1>
{/* 
                    <div className='home-image'>
                        <img className='home-section-image' src={dbImage} alt='' />
                    </div> */}
                </div>

                <div className="col-12 col-lg-5">
                    <h2 className="home-section-subtitle">{t('home.databaza_title')}</h2>
                    <p>{t('home.databaza_desc')}</p>
                    <a className="home-section-link link-underline" target="_blank" href="https://databaza.art/">{t('home.databaza_link')} <i class="fas fa-angle-right"></i></a>
                </div>
            </div>
        </div>
    )
}

export default Databaza;